<template>
    <div v-loading="$wait.is('loading.employees')" class="card" element-loading-spinner="el-custom-spinner">
        <h1 class="border-b pb-3 border-gray-400">
            {{ $t('dashboard.currently_working_employees') }}
        </h1>

        <el-table v-if="employeesData.length > 0" ref="table" :data="employeesData" style="width: 100%" :show-header="false">
            <el-table-column sortable width="60px">
                <template slot-scope="scope">
                    <el-avatar v-if="scope.row.employee_avatar" class="mt-1" :src="scope.row.employee_avatar" />
                    <div v-else class="p-1">
                        <img src="@/assets/images/unisex_user.png">
                    </div>
                </template>
            </el-table-column>
            <el-table-column sortable :show-overflow-tooltip="true" width="150px">
                <template slot-scope="scope">
                    <router-link :to="`/employees/${scope.row.employee_uuid}/information`" class="hover:text-red-500 hover:font-semibold">
                        {{ scope.row.employee_name }} {{ scope.row.employee_surname }}
                    </router-link>
                </template>
            </el-table-column>
            <el-table-column sortable :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{ scope.row.client }} - {{ scope.row.address }}
                </template>
            </el-table-column>
            <el-table-column v-if="userIsCleaningCompany()" sortable :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <div class="text-sm relative h-4 mr-1">
                        <fa-icon
                            v-if="calculateProgress(scope.row) < 100"
                            :icon="['far', 'walking']"
                            fixed-width
                            class="absolute -ml-2"
                            :style="{ left: calculateProgress(scope.row) + '%' }"
                        />
                        <fa-icon
                            v-if="calculateProgress(scope.row) === 100"
                            :icon="['fas', 'child']"
                            fixed-width
                            class="absolute -ml-2"
                            :style="{ left: calculateProgress(scope.row) + '%' }"
                        />
                    </div>
                    <el-progress
                        :percentage="calculateProgress(scope.row)"
                        :color="customColorMethod"
                        :format="format"
                    />
                    <div class="flex justify-between">
                        <p>
                            {{ formatTime(scope.row.started_event_at) }}
                        </p>
                        <p>
                            {{ formatTime(scope.row.end_event_at) }}
                        </p>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <NoDataInformation :data="noDataInformation" waitKey="loading.employees" />
    </div>
</template>
<script>
import Vue from 'vue';
import { avatar, progress } from 'element-ui';

Vue.use(avatar);
Vue.use(progress);

export default {
    data() {
        return {
            noDataInformation: false,
            employeesData:     [],
        };
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading.employees');
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/service_history/currently_working_employees`);
            this.employeesData = data;
            this.noDataInformation = data.length;
            this.$wait.end('loading.employees');
        },

        format() {
            return '';
        },

        formatTime(time) {
            return this.$dayjs(time, 'DD.MM.YYYY HH:mm').format('HH:mm');
        },

        calculateProgress(entry) {
            const startTime = this.$dayjs(entry.started_at, 'DD.MM.YYYY HH:mm');
            const endTime = this.$dayjs(entry.end_event_at, 'DD.MM.YYYY HH:mm');
            const shouldWorkMinutes = endTime.diff(startTime, 'minutes');
            const workedMinutes = this.$dayjs().diff(startTime, 'minutes');
            let percentage = (workedMinutes / shouldWorkMinutes) * 100;
            percentage = percentage < 0 ? 0 : percentage;
            percentage = percentage > 100 ? 100 : percentage;
            return percentage;
        },

        customColorMethod(percentage) {
            if (percentage === 100) {
                return '#71da9e';
            }

            return '#f26364';
        },
    },
};
</script>
<style>
.el-progress-bar {
    padding-right: 0;
}
</style>
